<template>
  <b-modal
    id="bv-modal-config-row"
    title="Cấu hình dòng"
    hide-footer
  >
    <div :class="{detail: isDetail}">
      <b-form-select
        v-model="selected"
        :options="options"
      />
    </div>
    <div class="mt-2 d-flex flex-row-reverse">
      <b-button
        size="sm"
        variant="danger"
        @click="hide()"
      >
        Hủy
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        class="mr-1"
        @click="save"
      >
        Lưu
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { CLASS_CONFIG } from '@/modules/quan-tri-he-thong/constants/constants'
import {
  BFormSelect,
  BButton,
  BModal,
} from 'bootstrap-vue'

export default {
  name: 'ConfigRow',
  components: {
    BFormSelect,
    BButton,
    BModal,
  },
  props: {
    currentClass: {
      type: Object,
      default: () => ({}),
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: CLASS_CONFIG,
      selected: null,
    }
  },
  watch: {
    currentClass: {
      deep: true,
      handler() {
        this.selected = this.currentClass.classRow
      },
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide('bv-modal-config-row')
    },
    show() {
      this.$bvModal.show('bv-modal-config-row')
    },
    save() {
      this.$emit('update-class', this.selected)
      this.hide()
    },
  },
}
</script>

<style scoped>
.detail {
  pointer-events: none
}
</style>
