<template>
  <b-card no-body>
    <b-card-body class="position-relative">
      <div class="wrap-btn-header">
        <b-button
          size="sm"
          variant="primary"
          class="mr-1"
          @click="onSaveForm"
        >
          Lưu
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
      <b-tabs
        class="p-0 tabs-custom-position"
      >
        <b-tab
          title="Thông tin chung"
          active
          :class="{'allow-update': isDetail || disabledStatus}"
        >
          <validation-observer
            ref="formValidate"
          >
            <b-form-group
              label="Tên biểu mẫu"
              class="required"
            >
              <validation-provider
                v-slot="{ errors }"
                key="tenBieuMau"
                :rules="{ required: true }"
                name="tenBieuMau"
              >
                <b-form-input
                  id="tenBieuMau"
                  ref="tenBieuMau"
                  v-model="form.name"
                  :autofocus="form.name ? false : true"
                  placeholder="Nhập tên biểu mẫu"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Tên bảng"
              class="required"
            >
              <validation-provider
                v-slot="{ errors }"
                key="tenBang"
                :rules="{ required: true }"
                name="tenBang"
              >
                <b-form-input
                  id="tenBang"
                  ref="tenBang"
                  v-model="form.tableName"
                  placeholder="Nhập tên bảng gán với biểu mẫu"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <good-table
              v-if="idUpdate"
              ref="bieuMau"
              class="mt-2 mb-2"
              :columns="columns"
              :rows="rows"
              :pagination="false"
              :selectable="false"
            />
            <b-form-group
              label="Trạng thái xử lý"
            >
              <b-form-checkbox
                v-model="form.state"
                :disabled="form.state && !!idUpdate"
                class="mb-1"
                switch
              />
            </b-form-group>
            <b-form-group label="Căn cứ pháp lý">
              <b-form-textarea
                v-model="form.canCuPhapLy"
                placeholder="Nhập căn cứ pháp lý"
              />
            </b-form-group>
          </validation-observer>
        </b-tab>
        <b-tab
          v-if="disabledStatus"
          title="Mẫu in"
        >
          <table class="table table-bordered custom-table">
            <thead>
              <tr>
                <th class="template-title">
                  Danh sách
                </th>
                <th class="d-flex justify-content-between">
                  <span class="d-flex align-items-center">
                    Chi tiết
                  </span>
                  <span>
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="newTemplate"
                    >
                      Thêm mới mẫu
                    </b-button>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="list-template">
                  <div class="wrap-template">
                    <div
                      v-for="(item, index) in listTemplate"
                      :key="item.id"
                      :class="{active: item.active}"
                      class="d-flex justify-content-between cursor-pointer"
                    >
                      <span @click="setActiveItem(index)">{{ item.name }}</span>
                      <feather-icon
                        v-if="index !== 0"
                        icon="Trash2Icon"
                        size="12"
                        @click="removeTemplate(index, item)"
                      />
                    </div>
                  </div>
                  <div class="wrap-tag">
                    <b-badge
                      v-for="(tag, index) in tagsTemplate"
                      :key="index"
                      v-b-tooltip.click.top.v-secondary="'Đã sao chép!'"
                      variant="primary"
                      @click="copyToClipboard(tag)"
                    >
                      {{ tag }}
                    </b-badge>
                  </div>
                </td>
                <td>
                  <div
                    v-for="item in listTemplate.filter(template => template.active)"
                    :key="item.id"
                    class="update-template"
                  >
                    <b-row>
                      <b-col
                        lg="5"
                        md="5"
                        sm="12"
                      >
                        <validation-observer
                          ref="validateMauIn"
                        >
                          <b-form-group
                            label="Tên mẫu in"
                            class="required"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="tenMauIn"
                              rules="required"
                            >
                              <b-form-input
                                id="tenMauIn"
                                v-model="item.name"
                                :disabled="item.name === 'Biểu mẫu mặc định'"
                                placeholder="Nhập tên mẫu in"
                              />
                              <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </b-form-group>
                        </validation-observer>
                      </b-col>
                      <b-col
                        lg="5"
                        md="5"
                        sm="12"
                      >
                        <b-form-group
                          label="Ghi chú"
                        >
                          <b-form-input
                            id="tenMauIn"
                            v-model="item.note"
                            placeholder="Nhập ghi chú"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="2"
                        md="2"
                        sm="12"
                      >
                        <b-form-group
                          label="Mặc định"
                        >
                          <b-form-checkbox
                            v-model="item.isDefault"
                            switch
                            @input="setDefaultTemplate(item)"
                          />
                        </b-form-group>

                      </b-col>
                    </b-row>
                    <b-form-group
                      label="Mẫu in"
                    >
                      <ckeditor
                        v-model="item.html"
                        :editor="editor"
                        :config="editorConfig"
                      />
                    </b-form-group>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
        <b-tab
          title="Thiết kế"
          class="frame"
          :class="{'allow-update': isDetail}"
        >
          <b-row>
            <b-col
              lg="2"
              class="menu"
            >
              <div class="header-text mb-2">
                Thành phần
              </div>
              <div class="configNavBar">
                <div
                  v-for="(control, index) in navbar"
                  :key="index"
                  class="pb-2"
                >
                  <div
                    class="item-collapse"
                    @click="collapseItem"
                  >
                    <img
                      alt="image control"
                      width="18px"
                      :src="getImgUrl(control.icon)"
                    >
                    {{ control.title }}
                    <feather-icon
                      :icon="
                        control.expand ? 'ChevronDownIcon' : 'ChevronRightIcon'
                      "
                      size="15"
                      style="float: right; margin-top: 2px"
                    />
                  </div>
                  <div
                    class="content-collapse mt-1"
                  >
                    <div
                      v-for="(i, idx) in control.children"
                      :key="i.type + idx"
                      class="input-txt mb-1"
                      @mousedown="setCurrentControl(i)"
                    >
                      {{ i.label }}
                    </div>
                  </div>
                </div>
              </div>

            </b-col>
            <b-col
              lg="10"
              class="modal-form"
            >
              <div class=" d-flex justify-content-between">
                <span class="header-text">Biểu mẫu</span>
                <b-button
                  size="sm"
                  variant="primary"
                  :class="{'pointer-events-none': idUpdate && form.state}"
                  @click="addRow"
                >
                  Thêm dòng
                </b-button>
              </div>
              <div class="config-column">
                <draggable
                  v-model="listRow"
                  class="row mr-1 ml-1"
                >
                  <frame-dropable
                    v-for="row in listRow"
                    ref="droplist"
                    :key="row.id"
                    :index="row.id"
                    :controls="row"
                    :class="`custom${row.id}`"
                    @list-control="updateListControl"
                    @delete-row="deleteRow"
                    @config-row="initUpdateRow"
                    @update-html="initUpdateHtml"
                    @update-control="initUpdateControl"
                    @duplicate-row="duplicateControls"
                    @duplicate-control="duplicateControl"
                    @delete-control="deleteControl"
                    @move-control="moveControl"
                  />
                </draggable>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          title="Xem trước"
          @click="setFormFields"
        >
          <b-card
            :key="preview"
            class="mb-0"
          >
            <div class="row">
              <FormulateForm autocomplete="off">
                <div
                  v-for="(row, formKey) in formControlView"
                  :key="formKey"
                  :class="row.className"
                >
                  <div class="row">
                    <div
                      v-for="(item, i) in row.listControl"
                      :key="i"
                      :class="item['wrap-class']"
                    >
                      <div
                        class="input-formulate"
                        :class="{ 'wrap-flex': item.horizontal} "
                      >
                        <FormulateInput
                          v-format-date
                          v-bind="item"
                          autocomplete="new-password"
                          :width="item.horizontal ? item.groupPosition.input : null"
                          :show-value="true"
                          :item="item"
                          :class="{
                            'left-icon': item.iconLeft,
                            'right-icon': item.iconRight,
                            'input-horizontal': item.horizontal
                          }"
                          :validation-rules="{
                            regex: ({ value }) => {
                              const regex = new RegExp(item.partternValue)
                              if(item.type === 'number') {
                                return regex.test(Number(value))
                              }
                              return regex.test(value)
                            }
                          }"
                          @control-value="setValueSchema($event, item, row)"
                          @input="setValueSchema($event, item, row)"
                        >
                          <template #label="{label}">
                            <div :style="{ width: item.horizontal ? `${item.groupPosition.label}%` : '100%', position: item.horizontal && item.iconLeft ? 'relative' : 'unset' }">
                              <label
                                v-if="item.html"
                                v-html="item.html"
                              />
                              <label
                                v-else-if="item.type !== 'customFile'"
                                :class="{required: item.validation && item.validation.includes('required')}"
                              >{{ label }}</label>
                              <span
                                v-if="item.horizontal && item.iconLeft"
                                class="icon-horizontal"
                                :class="{
                                  'left': item.iconLeft,
                                  'right': item.iconRight,
                                }"
                              >
                                <font-awesome-icon :icon="`fa-solid fa-${item.iconLeft}`" />
                              </span>
                            </div>
                          </template>
                        </FormulateInput>
                        <span
                          v-if="item.horizontal && item.iconRight"
                          class="input-icon-right"
                          :class="{'right': item.iconRight}"
                        >
                          <font-awesome-icon :icon="`fa-solid fa-${item.iconRight}`" />
                        </span>
                        <span
                          v-if="!item.horizontal && item.iconLeft"
                          class="input-icon"
                          :class="{'left': item.iconLeft}"
                        >
                          <font-awesome-icon :icon="`fa-solid fa-${item.iconLeft}`" />
                        </span>
                        <span
                          v-if="!item.horizontal && item.iconRight"
                          class="input-icon"
                          :class="{'right': item.iconRight}"
                        >
                          <font-awesome-icon :icon="`fa-solid fa-${item.iconRight}`" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </FormulateForm>
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card-body>
    <config-row
      ref="configRow"
      :current-class="configClassRow"
      :is-detail="isDetail"
      :is-update="idUpdate && form.state"
      @update-class="updateClassRow"
    />
    <update-control
      ref="updateControl"
      :control-info="controlUpdate"
      :list-control="listRow"
      :is-detail="isDetail"
      :is-update="idUpdate && form.state"
      @update-control="onUpdate"
    />
    <html-editor
      ref="htmlEditor"
      :config="editorConfig"
      :control-info="controlUpdate"
      :is-detail="isDetail"
      :is-update="idUpdate && form.state"
      @update-control="onUpdate"
    />
    <common-modal
      ref="commonModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="toListForm(), $refs.commonModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormTextarea,
  BFormCheckbox,
  VBToggle,
  BButton,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import { CONTROL_LIST, ROUTE_NAME } from '@/modules/quan-tri-he-thong/constants/constants'
import Ripple from 'vue-ripple-directive'
import 'jquery-ui/ui/widgets/draggable'
import $ from 'jquery'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import FrameDropable from '@/modules/quan-tri-he-thong/components/pages/FrameDropable.vue'
import ConfigRow from '@/modules/quan-tri-he-thong/components/pages/popup/ConfigRow.vue'
import UpdateControl from '@/modules/quan-tri-he-thong/components/pages/popup/UpdateControl.vue'
import HtmlEditor from '@/modules/quan-tri-he-thong/components/pages/popup/HtmlEditor.vue'
import 'vue-good-table/dist/vue-good-table.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import END_POINTS from '@/api/endpoints'
import _cloneDeep from 'lodash/cloneDeep'
import GoodTable from '@/components/GoodTable.vue'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import '@/utils/vi'

import draggable from 'vuedraggable'
import { compareObjects, removeDiacritical } from '@/utils'

const editorConfig = {
  language: 'vi',
  plugins: [
    EssentialsPlugin,
    LinkPlugin,
    BoldPlugin,
    ItalicPlugin,
    ParagraphPlugin,
    ImagePlugin,
    ImageCaptionPlugin,
    ImageStylePlugin,
    ImageToolbarPlugin,
    ImageUploadPlugin,
    AutoLink,
    Alignment,
    Table,
    TableToolbar,
    ListStyle,
    HeadingPlugin,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    Code,
    TableToolbar,
    TableCaption,
    TableProperties,
    TableCellProperties,
    Base64UploadAdapter,
  ],
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'toggleTableCaption',
      'tableProperties',
      'tableCellProperties'],
  },
  toolbar: {
    items: [
      'heading',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'subscript',
      'superscript',
      'uploadImage',
      'link',
      'alignment',
      'insertTable',
      'bulletedList',
      'numberedList',
      'code',
      'undo',
      'redo',
    ],
    shouldNotGroupWhenFull: true,
  },
}

export default {
  name: 'TaoHoSo',
  components: {
    BCard,
    BCardBody,
    CommonModal,
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
    FrameDropable,
    ConfigRow,
    UpdateControl,
    HtmlEditor,
    GoodTable,
    ValidationProvider,
    ValidationObserver,
    draggable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      editorConfig,
      isShow: false,
      editor: ClassicEditor,
      editorData: '',
      required,
      detailForm: false,
      isToggleControl: false,
      listRow: [],
      beginObject: {},
      currentControl: null,
      preview: 0,
      keyControl: 0,
      navbar: CONTROL_LIST,
      schema: [],
      configClassRow: {
        classRow: 'row',
        id: '',
      },
      controlUpdate: {},
      indexRowUpdate: null,
      form: {
        id: '',
        name: '',
        tableName: '',
        canCuPhapLy: '',
        state: true,
      },
      updateData: null,
      columns: [
        {
          label: 'Tên hiển thị',
          field: 'columnDisplayName',
          thClass: 'text-center',
        },
        {
          label: 'Tên cột',
          field: 'columnName',
          thClass: 'text-center',
        },
        {
          label: 'Kiểu dữ liệu',
          field: 'dbType',
          width: '250px',
          thClass: 'text-center',
        },
      ],
      rows: [],
      defaultTemplate: {
        listTemplate: [{
          name: 'Biểu mẫu mặc định',
          html: 'Sửa tại đây',
          isDefault: true,
        }],
      },
      disabledStatus: false,
      listTemplate: [],
      thongTinToKhai: [],
      formControlView: [],
    }
  },
  computed: {
    idUpdate() {
      return this.$route.params.id
    },
    isDetail() {
      return this.$route.meta.detail
    },
    tagsTemplate() {
      return this.rows.map(row => row.columnName)
    },
  },
  watch: {
    listRow: {
      handler() {
        this.formControlView = _cloneDeep(this.listRow)
      },
      deep: true,
    },
  },
  created() {
    if (this.idUpdate) {
      this.getDetailForm()
      this.getColumn()
    } else {
      this.beginObject = {
        ...this.form,
        ...this.listRow,
      }
    }
  },
  mounted() {
    $('.input-txt').draggable({
      revert: 'invalid',
      appendTo: 'body',
      cursorAt: {
        top: 0,
        left: 0,
      },
      cursor: '-webkit-grabbing',
      distance: 5,
      helper() {
        return '<div class="drag-icon" > + </div>'
      },
    })
    let thElm
    let startOffset

    Array.prototype.forEach.call(
      document.querySelectorAll('table th'),
      th => {
        // eslint-disable-next-line no-param-reassign
        th.style.position = 'relative'

        const grip = document.createElement('div')
        grip.innerHTML = '&nbsp;'
        grip.style.top = 0
        grip.style.right = 0
        grip.style.bottom = 0
        grip.style.width = '5px'
        grip.style.position = 'absolute'
        grip.style.cursor = 'col-resize'
        grip.addEventListener('mousedown', e => {
          thElm = th
          startOffset = th.offsetWidth - e.pageX
        })

        th.appendChild(grip)
      },
    )

    document.addEventListener('mousemove', e => {
      if (thElm) {
        thElm.style.width = `${startOffset + e.pageX}px`
      }
    })

    document.addEventListener('mouseup', () => {
      thElm = undefined
    })
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, {
        ...this.form,
        ...this.listRow,
      })) {
        this.toListForm()
      } else {
        this.$refs.commonModal.showModal()
        this.isShow = true
      }
    },
    getDetailForm() {
      this.$axios.get(END_POINTS.BIEU_MAU.capNhat(this.idUpdate)).then(response => {
        if (response.data.succeeded) {
          const form = response.data.data
          this.form = {
            name: form.name,
            tableName: form.tableName,
            canCuPhapLy: form.canCuPhapLy,
            state: form.state === 'APPROVED',
          }
          this.listRow = JSON.parse(form.content)
          this.beginObject = {
            ...this.form,
            ...this.listRow,
          }
          this.updateData = form
          if (form.state === 'APPROVED') {
            this.getTemplate()
            this.disabledStatus = true
          }
        }
      })
    },
    getTemplate() {
      this.$axios.get(END_POINTS.BIEU_MAU.templateBieuMau(this.idUpdate)).then(response => {
        if (response.data.succeeded) {
          this.listTemplate = response.data.data.map((template, index) => ({
            ...template,
            active: index === 0,
          }))
        }
      })
    },
    getColumn() {
      this.$axios.get(END_POINTS.BIEU_MAU.getColumnTable(this.idUpdate)).then(response => {
        if (response.data.succeeded) {
          this.rows = response.data.data
        }
      })
    },
    deleteRow(id) {
      this.listRow = this.listRow.filter(row => row.id !== id)
    },
    addRow() {
      this.listRow.push({
        id: this.listRow.length,
        listControl: [],
        className: 'col-md-12',
      })
      this.setThongTinToKhai()
    },
    duplicateControls(id) {
      const control = this.listRow.findIndex(row => row.id === id)
      this.listRow.splice(control + 1, 0, {
        id: this.listRow.length,
        listControl: this.listRow[control].listControl.map(ctrl => ({
          ...ctrl,
          key: `${ctrl.key}11`,
          code: `${ctrl.code}11`,
        })),
        className: this.listRow[control].className,
      })
    },
    duplicateControl(data, indexRow, keyControl) {
      const totalRow = this.listRow.length
      for (let i = 0; i < totalRow; i += 1) {
        if (this.listRow[i].id === indexRow) {
          this.listRow[i].listControl.push({ ...data, key: data.key + keyControl, name: data.name + keyControl })
          break
        }
      }
    },
    deleteControl(data, indexRow) {
      const totalRow = this.listRow.length
      for (let i = 0; i < totalRow; i += 1) {
        if (this.listRow[i].id === indexRow) {
          this.listRow[i].listControl = this.listRow[i].listControl.filter((control => control.key !== data.key))
          break
        }
      }
    },
    moveControl(listControl, indexRow) {
      const totalRow = this.listRow.length
      for (let i = 0; i < totalRow; i += 1) {
        if (this.listRow[i].id === indexRow) {
          this.listRow[i].listControl = listControl
          break
        }
      }
    },
    onSave() {
      const payload = this.$refs.hoSoForm.getRequestForm()
      this.$refs.hoSoForm.$refs.hoSoRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('hoSo/taoHoSo', payload)
        }
      })
    },
    onReset() {
      this.$nextTick(() => {
        this.$refs.hoSoForm.$refs.hoSoRules.reset()
      })
    },
    setCurrentControl(input) {
      this.keyControl += 1
      const defaultSetting = {
        title: `${input.label}${this.keyControl}`,
        key: `control${this.keyControl}`,
        name: `${input.label}${this.keyControl}`,
        code: `${input.label}${this.keyControl}`,
        'label-class': 'my-label-class',
        'wrap-class': ['col-md-12'],
        'validation-messages': {
          required: 'Trường không được để trống',
          regex: '',
        },
        placeholder: 'Nhập dữ liệu',
        configSearchSelected: [],
        horizontal: false,
        iconLeft: '',
        iconRight: '',
        disabled: false,
        isInput: true,
        groupPosition: {
          label: null,
          input: null,
        },
      }
      this.currentControl = { ...defaultSetting, ...input }
      if (input.label === 'html') {
        this.currentControl.label = '<p>sửa lại dòng chữ này</p>'
      }
    },
    updateListControl(id) {
      this.listRow.forEach((row, index) => {
        if (row.id === id) {
          this.listRow[index].listControl.push(this.currentControl)
        }
      })
      this.setThongTinToKhai()
      this.preview += 1
    },
    initUpdateRow(data) {
      this.configClassRow = data
      this.$nextTick(() => {
        this.$refs.configRow.show()
      })
    },
    updateClassRow(className) {
      this.listRow[this.configClassRow.id].className = className
    },
    initUpdateHtml(data, indexRow) {
      this.controlUpdate = data
      this.indexRowUpdate = indexRow
      this.$nextTick(() => {
        this.$refs.htmlEditor.show()
      })
    },
    initUpdateControl(data, indexRow) {
      this.controlUpdate = data
      this.indexRowUpdate = indexRow
      this.$nextTick(() => {
        this.$refs.updateControl.show()
      })
    },
    onUpdate(data) {
      const totalRow = this.listRow.length
      for (let i = 0; i < totalRow; i += 1) {
        if (this.listRow[i].id === this.indexRowUpdate) {
          const { listControl } = _cloneDeep(this.listRow[i])
          listControl.forEach((control, index) => {
            if (control.key === data.key) {
              listControl[index] = data
            }
          })
          this.listRow[i].listControl = listControl
          break
        }
      }
      this.$nextTick(() => {
        this.preview += 1
        setTimeout(() => {
          this.setInputWidth()
          this.setPositionRightIcon(data)
        }, 0)
      })
    },
    setInputWidth() {
      const el = document.querySelectorAll('.formulate-input-element input')
      for (let i = 0; i < el.length; i += 1) {
        el[i].parentElement.style.width = `${el[i].getAttribute('width') || 100}%`
      }
    },
    setPositionRightIcon(data) {
      if (data.horizontal && data.iconRight) {
        const el = document.querySelector('.input-icon-right')
        el.style.right = `${100 - data.groupPosition.label - data.groupPosition.input}%`
      }
    },
    collapseItem(el) {
      el.target.classList.toggle('active')
      el.currentTarget.classList.toggle('active')
      const content = el.target.nextElementSibling || el.currentTarget.nextElementSibling
      if (content.style.display === 'block') {
        content.style.display = 'none'
      } else {
        content.style.display = 'block'
      }
    },
    getImgUrl(icon) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`../../../../assets/images/svg/${icon}`)
    },
    toListForm() {
      this.$router.push(ROUTE_NAME.NAME + ROUTE_NAME.FORM.DANH_SACH)
    },
    getKeyUnique(value) {
      return `${removeDiacritical(this.form.name).replace(/\s/g, '')}_${value}`
    },
    async onSaveForm() {
      const validate = await this.$refs.formValidate.validate()
      if (validate) {
        const rowsMap = this.listRow.map(row => ({
          ...row,
          listControl: row.listControl.map(control => ({
            ...control,
            name: this.getKeyUnique(control.name.includes('_') ? control.name.split('_')[1] : control.name),
          })),
          fieldGroup: row.listControl.map(control => ({
            ...control,
            data: {
              type: 0,
              name: control.placeholder,
              key: control.label,
              group: 'input',
              availableDbType: [
                {
                  Name: control.unit,
                  Value: control.dbtype,
                },
              ],
              dbtypeName: control.unit,
              dbType: control.dbtype,
              columnName: control.name.includes('_') ? control.name.split('_')[0] : control.name,
              columnDisplayName: control.label,
              defaultValue: '',
              isFullTextSearch: false,
              Selected: false,
              IsExpand: false,
            },
          })),
        }))
        if (this.idUpdate) {
          this.updateForm(rowsMap)
        } else {
          this.createForm(rowsMap)
        }
      } else {
        const first = Object.entries(this.$refs.formValidate.errors).find(item => item[1].length > 0)
        this.handleFocusError(first)
      }
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['linhVuc', 'thuTucHanhChinh', 'mauHoSo'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs[field].focus()
        }
      }
    },
    createForm(rowsMap) {
      const rowMap = rowsMap.map(row => ({
        ...row,
        listControl: row.listControl.map(control => ({
          ...control,
          name: control.name.includes('_') ? control.name.split('_')[1] : control.name,
        })),
      }))
      const payload = {
        ...this.form,
        content: JSON.stringify(rowMap),
        state: this.form.state ? 'APPROVED' : 'DRAFT',
        listTemplate: this.listTemplate.length > 0 ? this.listTemplate : this.defaultTemplate.listTemplate,
      }
      this.$axios.post(END_POINTS.BIEU_MAU.URL(), this.$trimObject(payload)).then(res => {
        if (res.data.succeeded) {
          this.toListForm()
        }
      })
    },
    updateForm(rowsMap) {
      const rowMap = rowsMap.map(row => ({
        ...row,
        listControl: row.listControl.map(control => ({
          ...control,
          name: control.name.includes('_') ? control.name.split('_')[1] : control.name,
        })),
      }))
      // console.log(rowMap, 'rowsMap')
      const payload = {
        id: this.updateData.id,
        ...this.form,
        content: JSON.stringify(rowMap),
        state: this.form.state ? 'APPROVED' : 'DRAFT',
        listTemplate: this.listTemplate.length > 0 ? this.listTemplate : this.defaultTemplate.listTemplate,
      }
      // console.log(payload, 'payload gồm gì')
      this.$axios.put(END_POINTS.BIEU_MAU.capNhat(this.updateData.id), this.$trimObject(payload)).then(res => {
        if (res.data.succeeded) {
          this.toListForm()
        }
      })
    },
    setDefaultTemplate(item) {
      this.listTemplate.forEach((template, index) => {
        if (template.id !== item.id) {
          this.listTemplate[index].isDefault = false
        }
      })
    },
    setActiveItem(index) {
      this.listTemplate = this.listTemplate.map(template => ({
        ...template,
        active: false,
      }))
      if (this.listTemplate[index]) {
        this.listTemplate[index].active = true
      }
    },
    newTemplate() {
      // ref list
      this.$refs.validateMauIn[0].validate().then(response => {
        if (response) {
          this.listTemplate.push({
            html: '',
            name: '',
            node: '',
            active: true,
          })
          this.setActiveItem(this.listTemplate.length - 1)
          this.$refs.validateMauIn[0].reset()
        }
      })
    },
    removeTemplate(index, item) {
      this.listTemplate = this.listTemplate.filter((template, idx) => idx !== index)
      if (item.active) {
        this.listTemplate[0].active = true
      }
    },
    copyToClipboard(value) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(`{{${value}}}`)
      }
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip')
      }, 800)
    },
    setThongTinToKhai() {
      this.thongTinToKhai = this.listRow.map(row => ({
        id: row.id,
        listControl: row.listControl.map(control => ({
          key: control.code,
          value: '',
          dbType: control.dbtype,
        })),
      }))
    },
    setValueSchema(value, control, row) {
      if (value) {
        this.thongTinToKhai.forEach((toKhai, index) => {
          if (toKhai.id === row.id) {
            const idx = this.thongTinToKhai[index].listControl.findIndex(ctr => ctr.key === control.code)
            if (idx !== -1) {
              this.thongTinToKhai[index].listControl[idx].value = value.toString()
            }
          }
        })
        this.setFormFields()
      }
    },
    setFormFields() {
      const controlFlatten = this.thongTinToKhai.reduce((current, next) => [...current, ...next.listControl], [])
      this.listRow.forEach((row, indexRow) => {
        this.formControlView[indexRow].listControl = this.listRow[indexRow].listControl.filter(control => {
          if (control.isHideExpression) {
            let result = true
            const controlCompare = control.hideExpression.replace(/ /g, '').split(':')
            if (controlCompare.length > 0) {
              controlFlatten.forEach(controlFl => {
                if (controlFl.key === controlCompare[0] && controlFl.value === controlCompare[1]) {
                  result = false
                }
              })
            }
            return result
          }
          return true
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.frame {
  border: 1px solid rgb(214, 206, 206);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 5px;
  padding: 0;
}

.header-text {
  color: #4488e3;
  font-weight: bold;
}

.menu {
  padding: 15px 15px 0 30px;
  border-right: 1px solid rgb(214, 206, 206);
}

.config-column {
  margin-top: 20px;
  background-color: #f3f2f2;
  height:calc(100vh - 400px);
  overflow:auto;
  padding: 20px;
  border-radius: 5px;
}
 .config-column::-webkit-scrollbar {
    width: 3px;
    background-color:#E5E5E5;
}
 .config-column::-webkit-scrollbar-thumb {
     background-color: gray;
}
 .config-column::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffff;
}
.configNavBar {
  height:calc(100vh - 400px);
  overflow:auto;
}
 .configNavBar::-webkit-scrollbar {
    width: 3px;
    background-color:#E5E5E5;
}
 .configNavBar::-webkit-scrollbar-thumb {
     background-color: gray;
}
 .configNavBar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffff;
}
.modal-form {
  padding: 15px 30px 15px 15px;
}

.button-toggle {
  padding: 10px;
  border-radius: 5px;
}

.collapse-menu {
  background: #7367f0;
  color: white;
  margin-bottom: 10px;
  border-radius: 5px;
}
.collapse-menu > div {
  padding: 10px;
}
.collapse-menu > div:hover {
  background: #8c83f0;
  border-radius: 5px;
}

.input-formulate {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 23px;
  padding: 8px 12px;
  background: #7367f0;
  color: white;
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
}
.content-collapse {
  padding: 0 18px;
  display: none;
  overflow: hidden;
}

.item-collapse {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
  &.active {
    background-color: #f3f2f2;
    svg {
      transform: rotate(90deg)
    }
  }
}

.input-txt {
  cursor: grab;
}

.detail-text {
  cursor: pointer;
}

.nav-tabs {
  justify-content: end;
}

.wrap-btn-header {
  z-index: 1;
  position: absolute;
  left: 20px;
  top: 18px;
}

.template-title {
  width: 256px;
}

.update-template {
  width: 100%;
}

.custom-table {
  thead {
    th {
      background-color: unset !important;
      border: 1px solid #ebe9f1;
      vertical-align: middle;
      &:last-of-type {
        border: none;
      }
    }
  }

  .list-template {
    vertical-align: unset;
    padding: 0;
    div {
      padding: 5px;
      &.wrap-template {
        background-color: #f8f8f8;
        max-height: 150px;
        min-height: 150px;
        overflow: hidden auto;
      }
      &.wrap-tag {
        cursor: pointer;
        span {
          margin-bottom: 5px;
          margin-right: 5px;
        }
      }
      &.active {
        background-color: #005bd4;
        color: white
      }
      svg {
        margin-top: 5px;
      }
      span {
        width: 100%;
        min-width: 80%;
        min-height: 100%;
        &.badge {
          width: unset;
          min-width: unset;
          min-height: unset;
        }
      }
    }
  }
}

.allow-update {
  pointer-events: none
}

.custom-tooltip {
  display: none;
}
</style>
