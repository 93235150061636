<template>
  <div :class="['mb-1', className === 'row' ? 'col-md-12' : className]">
    <div class="block">
      <div
        ref="block"
        class="dropable"
      >
        <draggable
          v-model="listControl"
          class="row mr-1 ml-1"
          @end="onEnd"
        >
          <div
            v-for="(control, index) in listControl"
            :key="index"
            :class="control['wrap-class']"
          >
            <b-row
              v-if="control.html"
              class="control mb-2 control-html"
            >
              <b-col lg="12">
                <div v-html="control.html" />
              </b-col>
              <div class="actions">
                <button
                  class="action-button setting"
                  @click="htmlEditor(control)"
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="15"
                    class="icon"
                  />
                </button>
                <button
                  class="action-button delete"
                  @click="deleteControl(control)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="15"
                    class="icon"
                  />
                </button>
                <button
                  class="action-button copy"
                  @click="duplicateControl(control)"
                >
                  <feather-icon
                    icon="CopyIcon"
                    size="15"
                    class="icon"
                  />
                </button>
              </div>
            </b-row>
            <div
              v-else
              class="control mb-2"
            >
              <label class="title">{{ control.title }}</label>
              <b-col lg="12">
                <b-row>
                  <b-col>
                    <div class="pl-3 control-content">
                      <div>
                        <span class="label-control">Nhãn:</span>
                        {{ control.label }}
                      </div>
                      <div>
                        <span class="label-control">Tên cột: </span>
                        {{ control.name }}
                      </div>
                      <div>
                        <span class="label-control">Mã cột: </span>
                        {{ control.code }}
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <div class="actions">
                <button
                  class="action-button setting"
                  @click="initialUpdate(control)"
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="15"
                    class="icon"
                  />
                </button>
                <button
                  class="action-button copy"
                  @click="duplicateControl(control)"
                >
                  <feather-icon
                    icon="CopyIcon"
                    size="15"
                    class="icon"
                  />
                </button>
                <button
                  class="action-button delete"
                  @click="deleteControl(control)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="15"
                    class="icon"
                  />
                </button>
              </div>
            </div>
          </div>
        </draggable>
        <div class="actions">
          <button
            class="action-button setting"
            @click="openConfig"
          >
            <feather-icon
              icon="SettingsIcon"
              size="15"
              class="icon"
            />
          </button>
          <button
            class="action-button copy"
            @click="duplicateRow"
          >
            <feather-icon
              icon="CopyIcon"
              size="15"
              class="icon"
            />
          </button>
          <button
            class="action-button delete"
            @click="deleteRow"
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
              class="icon"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import 'jquery-ui/ui/widgets/droppable'
import $ from 'jquery'
import draggable from 'vuedraggable'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'FrameDropable',
  components: {
    draggable,
    BRow,
    BCol,
  },
  props: {
    controls: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  data() {
    return {
      listControl: [],
      controlUPdate: {},
      indexUpdate: null,
      className: 'col-md-12',
      keyControl: 0,
    }
  },
  watch: {
    controls: {
      handler() {
        this.listControl = _cloneDeep(this.controls.listControl)
        this.className = this.controls.className
        this.indexUpdate = this.controls.id
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const vm = this
    $(`.custom${this.index} .dropable`).droppable({
      accept: '.input-txt',
      tolerance: 'pointer',
      over() {
        $(this).addClass('drop-hover')
      },
      out() {
        $(this).removeClass('drop-hover')
      },
      drop() {
        vm.emitControl()
      },
    })
  },
  methods: {
    deleteRow() {
      this.$emit('delete-row', this.indexUpdate)
    },
    openConfig() {
      this.$emit('config-row', { classRow: this.className, id: this.indexUpdate })
    },
    duplicateRow() {
      this.$emit('duplicate-row', this.indexUpdate)
    },
    onEnd() {
      this.$emit('move-control', this.listControl, this.indexUpdate)
    },
    deleteControl(control) {
      this.$emit('delete-control', control, this.indexUpdate)
    },
    duplicateControl(control) {
      this.$emit('duplicate-control', control, this.indexUpdate, this.keyControl)
      this.keyControl += 1
    },
    initialUpdate(control) {
      this.$nextTick(() => {
        this.$emit('update-control', control, this.indexUpdate)
      })
    },
    htmlEditor(control) {
      this.$nextTick(() => {
        this.$emit('update-html', control, this.indexUpdate)
      })
    },
    emitControl() {
      this.$emit('list-control', this.indexUpdate)
    },
  },
}
</script>

<style lang="scss" scoped>
.block {
  margin-bottom: 10px;
}
.dropable {
  position: relative;
  min-height: 67px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}
.actions {
  max-width: 20px;
  position: absolute;
  top: 0;
  right: 6px;
  .setting {
    pointer-events: all !important;
  }
}

.control {
  position: relative;
  text-align: left;
  outline: 3px solid #7367f0;
  max-width: 98%;
  margin-left: 0;
}
.ui-state-highlight {
  background-color: orange;
}
.action-button {
  border: none;
}

.delete {
  background-color: red;
}

.icon {
  color: white;
}

.setting {
  background-color: #337ab7;
}

.copy {
  background-color: #7367f0;
}

.title {
  color: white;
  font-weight: bold;
  background-color: #7367f0;
  padding: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.control-content {
  margin-top: 30px;
  margin-bottom: 10px;
}

.label-control {
  color: #7367f0;
  font-weight: bold;
}

.control-html {
  min-height: 100px;
}
</style>
