<template>
  <b-modal
    id="bv-modal-html"
    size="custom-width"
    title="Cấu hình trường tin"
    hide-footer
    :class="{detail: isDetail}"
  >
    <b-row :class="{'pointer-events-none': isUpdate}">
      <b-col lg="12">
        <b-form-group label="Css Class">
          <b-form-select
            v-model="classSelected"
            :options="options"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <ckeditor
          v-model="editorData"
          :editor="editor"
          :config="editorConfig"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        lg="12"
        class="d-flex flex-row-reverse"
      >
        <b-button
          size="sm"
          variant="danger"
          @click="hide()"
        >
          Hủy
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          class="mr-1"
          @click="save"
        >
          Lưu
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import '@/utils/vi'

import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import { CLASS_CONFIG } from '@/modules/quan-tri-he-thong/constants/constants'

export default {
  name: 'UpdateControl',
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
  },
  props: {
    controlInfo: {
      type: Object,
      default: () => ({}),
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: CLASS_CONFIG,
      dataControl: {},
      require: false,
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        language: 'vi',
        // The configuration of the editor.
        plugins: [
          EssentialsPlugin,
          LinkPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          AutoLink,
          Alignment,
          Table,
          TableToolbar,
          ListStyle,
          HeadingPlugin,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          Code,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
        ],
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'toggleTableCaption',
            'tableProperties',
            'tableCellProperties'],
        },
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'uploadImage',
            'link',
            'alignment',
            'insertTable',
            'bulletedList',
            'numberedList',
            'code',
            'undo',
            'redo',
          ],
        },
      },
      classSelected: '',
    }
  },
  watch: {
    controlInfo() {
      this.dataControl = { ...this.controlInfo }
      this.editorData = this.dataControl.html
      // eslint-disable-next-line prefer-destructuring
      this.classSelected = this.dataControl['wrap-class'][0]
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide('bv-modal-html')
    },
    show() {
      this.$bvModal.show('bv-modal-html')
    },
    save() {
      this.dataControl.html = this.editorData
      this.dataControl.tooltip = this.editorData
      this.dataControl['wrap-class'] = [this.classSelected]
      this.$emit('update-control', this.dataControl)
      this.hide()
    },
    stripHtml(html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
  },
}
</script>

<style scoped>
.detail {
  pointer-events: none
}
</style>
