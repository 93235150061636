<template>
  <tao-form />
</template>

<script>
import TaoForm from '@/modules/quan-tri-he-thong/components/pages/TaoForm.vue'

export default {
  name: 'TrangTaoBieuMau',
  components: {
    TaoForm,
  },
}
</script>

<style scoped></style>
