<template>
  <b-modal
    id="bv-modal-control"
    title="Cấu hình trường tin"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    size="custom-width"
    @show="(tabActive = 1)"
    @hide="resetForm"
  >
    <template v-slot:modal-header>
      <div class="d-flex justify-content-between w-100">
        <h5 class="pl-2 pt-1 pb-1 mb-0">
          Cấu hình trường tin
        </h5>
        <div class="d-flex pr-2 menu-tabs">
          <div
            class="pl-1 d-flex align-items-center pr-1"
            :class="{'tab-active': tabActive === 1}"
            @click="(tabActive = 1)"
          >
            Thông tin chung
          </div>
          <div
            class="pl-1 d-flex align-items-center pr-1"
            :class="{'tab-active': tabActive === 2}"
            @click="(tabActive = 2)"
          >
            Nâng cao
          </div>
          <div
            class="pr-1 d-flex align-items-center pl-1"
            :class="{'tab-active': tabActive === 3}"
            @click="(tabActive = 3)"
          >
            Xem trước
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="tabActive === 1"
      :class="{detail: isDetail, 'pointer-events-none': isUpdate}"
    >
      <b-row class="mb-1">
        <b-col lg="6">
          <b-form-group label="Tên cột">
            <b-form-input
              v-model="formField.name"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Mã cột(Trong CSDL)">
            <b-form-input
              v-model="formField.code"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col lg="6">
          <b-form-group label="Css Class">
            <b-form-select
              v-model="formField.classSelected"
              :options="options"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Trường tin">
            <b-form-select
              v-model="formField.id"
              :options="controlList"
              size="sm"
              @input="setDataType"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Kiểu dữ liệu">
            <b-form-input
              v-model="formField.unit"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col>
          <b-form-group label="Cấu hình tìm kiếm">
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="formField.configSearchSelected"
              :options="configSearchOptions"
              name="flavour-1"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mb-1 mt-1">
        <b-col lg="6">
          <b-form-group label="Tên nhãn">
            <b-form-input
              id="input-1"
              v-model="formField.label"
            />
          </b-form-group>
          <b-row>
            <b-col lg="6">
              <b-form-checkbox v-model="formField.horizontal">
                Hiển thị chiều ngang
              </b-form-checkbox>
            </b-col>
            <b-col
              v-if="formField.horizontal"
              lg="3"
            >
              <b-form-input
                v-model="formField.groupPosition.label"
                size="sm"
                trim
                placeholder="50%"
              />
            </b-col>
            <b-col
              v-if="formField.horizontal"
              lg="3"
            >
              <b-form-input
                v-model="formField.groupPosition.input"
                size="sm"
                trim
                placeholder="50%"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Ô gợi ý">
            <b-form-input
              id="input-2"
              v-model="formField.placeholder"
              trim
            />
          </b-form-group>
          <b-form-checkbox-group
            v-model="formField.validateSelected"
            :options="validateOptions"
            name="flavour-1"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          v-if="checkElementBeShow.includes('configicon')"
          lg="3"
        >
          <b-form-group label="Biểu tượng trái">
            <b-form-input
              v-model="formField.iconLeft"
              placeholder="Mã icon"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="checkElementBeShow.includes('configicon')"
          lg="3"
        >
          <b-form-group label="Biểu tượng Phải">
            <b-form-input
              v-model="formField.iconRight"
              placeholder="Mã icon"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="checkElementBeShow.includes('configlength')"
          lg="3"
        >
          <b-form-group label="Ký tự tối đa">
            <b-form-input
              v-model="formField.maxLength"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="checkElementBeShow.includes('configlength')"
          lg="3"
        >
          <b-form-group label="Ký tự tối thiểu">
            <b-form-input
              v-model="formField.minLength"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="checkElementBeShow.includes('configrange')"
          lg="3"
        >
          <b-form-group label="Giá tối đa">
            <b-form-input
              v-model="formField.max"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="checkElementBeShow.includes('configrange')"
          lg="3"
        >
          <b-form-group label="Giá trị tối thiểu">
            <b-form-input
              v-model="formField.min"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="checkElementBeShow.includes('table')">
        <b-col>
          <vue-good-table
            :key="keyTable"
            ref="vueGoodTable"
            :columns="getColumn()"
            :rows="rows"
          >
            <div
              slot="emptystate"
              class="text-center"
            >
              Không có dữ liệu
            </div>
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span v-if="props.column.label =='action'">
                <feather-icon
                  icon="PlusCircleIcon"
                  size="20"
                  class="icon-color"
                  @click="addNewOption"
                />
              </span>
              <span v-else>
                {{ props.column.label }}
              </span>
            </template>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <div
                v-if="props.column.field === 'group'"
                class="text-wrap"
              >
                <b-form-input
                  v-model="props.row.group"
                  size="sm"
                  @change="updatedRow(props.index, props.row, 'rows')"
                />
              </div>
              <div
                v-if="props.column.field === 'label'"
                class="text-wrap"
              >
                <b-form-input
                  v-model="props.row.label"
                  size="sm"
                  @change="updatedRow(props.index, props.row, 'rows')"
                />
              </div>
              <div
                v-if="props.column.field === 'value'"
                class="text-wrap"
              >
                <b-form-input
                  v-model="props.row.value"
                  size="sm"
                  @change="updatedRow(props.index, props.row, 'rows')"
                />
              </div>
              <div
                v-if="props.column.field === 'action'"
                class="text-wrap"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="20"
                  @click="deleteRow(props.index)"
                />
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <b-row v-if="checkElementBeShow.includes('config-table')">
        <b-col>
          <vue-good-table
            :columns="configTableColumns"
            :rows="configTableRows"
          >
            <div
              slot="emptystate"
              class="text-center"
            >
              Không có dữ liệu
            </div>
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span v-if="props.column.label =='action'">
                <feather-icon
                  icon="PlusCircleIcon"
                  size="20"
                  class="icon-color"
                  @click="addNewColumnConfig"
                />
              </span>
              <span v-else>
                {{ props.column.label }}
              </span>
            </template>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <div
                v-if="props.column.field === 'columnName'"
                class="text-wrap"
              >
                <b-form-input
                  v-model="props.row.columnName"
                  size="sm"
                  @change="updatedRow(props.index, props.row, 'configTableRows')"
                />
              </div>
              <div
                v-if="props.column.field === 'attribute'"
                class="text-wrap"
              >
                <b-form-input
                  v-model="props.row.attribute"
                  size="sm"
                  @change="updatedRow(props.index, props.row, 'configTableRows')"
                />
              </div>
              <div
                v-if="props.column.field === 'dataType'"
                class="text-wrap"
              >
                <b-form-select
                  v-model="props.row.dataType"
                  :options="optionsType"
                  @change="updatedRow(props.index, props.row, 'configTableRows')"
                />
              </div>
              <div
                v-if="props.column.field === 'action'"
                class="text-wrap"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="20"
                  @click="deleteRow(props.index)"
                />
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <b-row
        v-if="checkElementBeShow.includes('table')"
        class="mt-2"
      >
        <b-col>
          <span class="title-api">Sử dụng từ API:</span>
          <b-form-checkbox
            v-model="formField.apiData"
            :value="true"
            class="checkbox-api"
          />
          <div v-if="formField.apiData">
            <b-form-group
              label="Đường dẫn API"
            >
              <b-form-input
                v-model="formField.url"
              />
            </b-form-group>
            <b-form-group
              label="Thuộc tính Dữ liệu"
            >
              <b-form-input
                v-model="formField.data"
              />
            </b-form-group>
            <hr class="mt-2">
          </div>
          <b-form-group
            label="Thuộc tính tên"
          >
            <b-form-input
              v-model="formField.configApi.label"
            />
          </b-form-group>
          <b-form-group label="Thuộc tính giá trị">
            <b-form-input
              v-model="formField.configApi.name"
            />
          </b-form-group>
          <b-form-group
            v-if="checkElementBeShow.includes('groupApi')"
            label="Nhóm"
          >
            <b-form-input
              v-model="formField.configApi.group"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="checkElementBeShow.includes('file')">
        <b-col lg="6">
          <b-row>
            <b-col lg="6">
              <b-form-group
                label="Dung lượng tối đa (MB)"
              >
                <b-form-input
                  v-model="formField.fileConfig.capacity"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Sử dụng thuộc tính"
              >
                <b-form-input
                  v-model="formField.fileConfig.prop"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <b-form-group
                label="Thuộc tính tên"
              >
                <b-form-input
                  v-model="formField.fileConfig.name"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group
                label="Thuộc tính kích cỡ"
              >
                <b-form-input
                  v-model="formField.fileConfig.size"
                />
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group
                label="Header parameter"
              >
                <b-form-input
                  v-model="formField.fileConfig.params"
                />
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group
                label="Body payload"
              >
                <b-form-input
                  v-model="formField.fileConfig.body"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col lg="12">
              <b-form-group
                label="Kiểu dữ liệu cho phép"
              >
                <b-form-input
                  v-model="formField.fileConfig.dataType"
                />
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group
                label="Thư mục tải lên"
              >
                <b-form-input
                  v-model="formField.fileConfig.uploadFolder"
                />
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group
                label="Đường dẫn API tải về"
              >
                <b-form-input
                  v-model="formField.fileConfig.apiDownload"
                />
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group
                label="Đường dẫn api tải lên"
              >
                <b-form-input
                  v-model="formField.fileConfig.apiUpload"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="tabActive === 2"
      :class="{detail: isDetail, 'pointer-events-none': isUpdate}"
    >
      <b-form-group label="Danh sách trường tin khác">
        <ul>
          <li
            v-for="control in controls"
            :key="control"
          >
            {{ control }}
          </li>
        </ul>
      </b-form-group>
      <div class="form-group">
        <label>Sử dụng xác thực Parttern:</label>
        <b-form-checkbox
          v-model="formField.isPartternValidation"
          class="checkbox-api ml-1"
        />
      </div>
      <div v-if="formField.isPartternValidation">
        <div
          class="form-group"
        >
          <label>Điều kiện Parttern</label>
          <b-form-input
            v-model="formField.partternValue"
          />
        </div>
        <div
          class="form-group"
        >
          <label>Thông báo lỗi</label>
          <b-form-input
            v-model="partternMessage"
          />
        </div>
      </div>
      <div class="form-group">
        <label>Điều kiện ẩn:</label>
        <b-form-checkbox
          v-model="formField.isHideExpression"
          class="checkbox-api ml-1"
        />
      </div>
      <div
        v-if="formField.isHideExpression"
        class="form-group"
      >
        <label>Điều kiện (return true|false): <small class="hide-condition">truong_tin:value</small></label>
        <b-form-input
          v-model="formField.hideExpression"
        />
      </div>
    </div>
    <div
      v-if="tabActive === 3"
      :class="{detail: isDetail, 'pointer-events-none': isUpdate}"
    >
      <div
        class="input-formulate"
        :class="{ 'wrap-flex': formField.horizontal} "
      >
        <FormulateInput
          v-format-date
          v-bind="formField"
          :width="formField.horizontal ? formField.groupPosition.input : null"
          :show-value="true"
          :item="formField"
          :validation-rules="{
            regex: ({ value }) => {
              const regex = new RegExp(item.partternValue)
              if(item.type === 'number') {
                return regex.test(Number(value))
              }
              return regex.test(value)
            }
          }"
          :class="{
            'left-icon': formField.iconLeft,
            'right-icon': formField.iconRight,
          }"
        >
          <template #label="{label}">
            <div :style="{ width: formField.horizontal ? `${formField.groupPosition.label}%` : '100%', position: formField.horizontal && formField.iconLeft ? 'relative' : 'unset' }">
              <label
                v-if="formField.html"
                v-html="formField.html"
              />
              <label v-else-if="formField.type !== 'customFile'">{{ label }}</label>
              <span
                v-if="formField.horizontal && formField.iconLeft"
                class="icon-horizontal"
                :class="{
                  'left': formField.iconLeft,
                  'right': formField.iconRight,
                }"
              >
                <font-awesome-icon :icon="`fa-solid fa-${formField.iconLeft}`" />
              </span>
            </div>
          </template>
        </FormulateInput>
        <span
          v-if="formField.horizontal && formField.iconRight"
          class="input-icon-right"
          :class="{'right': formField.iconRight}"
        >
          <font-awesome-icon :icon="`fa-solid fa-${formField.iconRight}`" />
        </span>
        <span
          v-if="!formField.horizontal && formField.iconLeft"
          class="input-icon"
          :class="{'left': formField.iconLeft}"
        >
          <font-awesome-icon :icon="`fa-solid fa-${formField.iconLeft}`" />
        </span>
        <span
          v-if="!formField.horizontal && formField.iconRight"
          class="input-icon"
          :class="{'right': formField.iconRight}"
        >
          <font-awesome-icon :icon="`fa-solid fa-${formField.iconRight}`" />
        </span>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col
        lg="12"
        class="d-flex flex-row-reverse"
      >
        <b-button
          size="sm"
          variant="danger"
          @click="hide()"
        >
          Hủy
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          class="mr-1"
          @click="save"
        >
          Lưu
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSelect,
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { CLASS_CONFIG, CONTROL_LIST } from '@/modules/quan-tri-he-thong/constants/constants'
import _cloneDeep from 'lodash/cloneDeep'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

const DEFAUL_ROWS = [
  { value: 'first', label: 'First name', group: '' },
  { value: 'last', label: 'Last name', group: '' },
  { value: 'initial', label: 'Middle Initial', group: '' },
  { value: 'maiden', label: 'Maiden name', group: '' },
]

export default {
  name: 'UpdateControl',
  components: {
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueGoodTable,
  },
  props: {
    controlInfo: {
      type: Object,
      default: () => ({}),
    },
    listControl: {
      type: Array,
      default: () => ([]),
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CONTROL_LIST,
      require: false,
      options: CLASS_CONFIG,
      intersection: '',
      configSearchOptions: [
        { text: 'Hiển thị danh sách', value: 'displayList' },
        { text: 'Ẩn thông tin chung', value: 'hideData' },
        { text: 'Tìm kiếm full text', value: 'searchFullText' },
      ],
      validateOptions: [
        { text: 'Bắt buộc nhập', value: 'required' },
        { text: 'Chỉ đọc', value: 'readonly' },
        { text: 'Đặt mặc định', value: 'default' },
      ],
      formField: {
        url: '',
        data: '',
        configSearchSelected: [],
        classSelected: '',
        validateSelected: [],
        name: '',
        code: '',
        type: '',
        unit: null,
        iconLeft: '',
        iconRight: '',
        maxLength: '',
        minLength: '',
        label: '',
        placeholder: '',
        groupPosition: {
          label: null,
          input: null,
        },
      },
      controlList: this.getControlList(),
      columns: [
        {
          label: 'Nhóm',
          field: 'group',
        },
        {
          label: 'Tên',
          field: 'label',
        },
        {
          label: 'Giá trị',
          field: 'value',
        },
        {
          label: 'action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      rows: _cloneDeep(DEFAUL_ROWS),
      configTableColumns: [
        {
          label: 'Tên Cột',
          field: 'columnName',
        },
        {
          label: 'Tên thuộc tính',
          field: 'attribute',
        },
        {
          label: 'Kiểu dữ liệu',
          field: 'dataType',
        },
        {
          label: 'action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      configTableRows: [],
      optionsType: [
        { value: 'Number', text: 'Number' },
        { value: 'Text', text: 'Text' },
        { value: 'Date time', text: 'Date time' },
      ],
      tabActive: 1,
      keyTable: 0,
      partternMessage: '',
    }
  },
  computed: {
    checkElementBeShow() {
      const control = this.controlList.find(item => item.value === this.formField.id)
      this.assignControl(control)
      return control ? control.showElement : []
    },
    controls() {
      const arr = []
      this.listControl.forEach(row => {
        arr.push(...row.listControl.map(control => control.code))
      })
      return arr
    },
  },
  watch: {
    controlInfo: {
      deep: true,
      handler() {
        this.initData()
      },
    },
  },
  methods: {
    resetForm() {
      this.rows = _cloneDeep(DEFAUL_ROWS)
      this.initData()
    },
    initData() {
      this.formField = _cloneDeep(this.controlInfo)
      this.intersection = this.options.filter(element => this.formField['wrap-class'].includes(element))
      if (this.intersection.length) {
        // eslint-disable-next-line prefer-destructuring
        this.formField.classSelected = this.intersection[0]
      }
      this.formField.maxLength = this.findRangeValue('max')
      this.formField.minLength = this.findRangeValue('min')
      if (['radio', 'checkbox'].includes(this.formField.type)) {
        this.rows = this.formField.options || []
      }
      if (this.formField.type === 'treeSelect' && this.formField.group) {
        const options = []
        this.formField.options.forEach(option => {
          options.push(...option.child)
        })
        this.rows = options
      }
      if (this.formField.type === 'table') {
        this.configTableRows = this.formField.options
      }
    },
    getColumn() {
      if (['radio', 'checkbox'].includes(this.formField.type)) {
        return this.columns.filter(col => col.field !== 'group')
      }
      return this.columns
    },
    assignControl(control) {
      const objRemoveLabel = { ...control, label: this.formField.label, value: undefined }
      this.formField = { ...this.formField, ...objRemoveLabel }
    },
    findRangeValue(key) {
      const index = this.formField.validation.indexOf(key)
      if (index !== -1) {
        const string = this.formField.validation.slice(index + 3, index + 7)
        // eslint-disable-next-line prefer-destructuring
        return string.match(/\d+/)[0]
      }
      return ''
    },
    hide() {
      this.$bvModal.hide('bv-modal-control')
    },
    show() {
      this.$bvModal.show('bv-modal-control')
    },
    save() {
      const payload = _cloneDeep(this.formField)
      if (this.formField.validateSelected && this.formField.validateSelected.includes('required')) {
        payload.validation = 'required'
        if (['input-email'].includes(this.formField.id)) {
          payload.validation = 'required|email'
        }
        if (['input-url'].includes(this.formField.id)) {
          payload.validation = 'required|url'
        }
        payload['validation-formkit'] = 'required'
      } else {
        if (['input-email', 'input-url'].includes(this.formField.id)) {
          payload.validation = payload.validation.replace('email', '').replace('url', '')
        }
        payload.validation = payload.validation.replace('required', '')
        payload['validation-formkit'] = payload['validation-formkit'] ? payload['validation-formkit'].replace('required', '') : ''
      }
      if (![NaN, 0].includes(Number(this.formField.maxLength))) {
        payload.validation += `|max:${this.formField.maxLength},length`
        this.$set(payload['validation-messages'], 'max', `Tối đa ${this.formField.maxLength} ký tự`)
        this.$set(payload['validation-messages'], 'length', `Trường dữ liệu không được vượt quá ${this.formField.maxLength} ký tự`)
        payload['validation-formkit'] += `|length:${this.formField.minLength || 0},${this.formField.maxLength}`
      }
      if (![NaN, 0].includes(Number(this.formField.minLength))) {
        payload.validation += `|min:${this.formField.minLength},length`
        this.$set(payload['validation-messages'], 'min', `Tối thiểu ${this.formField.minLength} ký tự`)
        this.$set(payload['validation-messages'], 'length', `Trường dữ liệu phải có tối thiểu ${this.formField.minLength} ký tự`)
        if (![NaN, 0].includes(Number(this.formField.maxLength))) {
          this.$set(payload['validation-messages'], 'length', `Trường dữ liệu phải có độ dài từ ${this.formField.minLength} đến ${this.formField.maxLength}`)
        }
      }
      if (this.formField.isPartternValidation) {
        payload['validation-messages'].regex = this.partternMessage
        payload.validation += '|regex'
        payload['validation-formkit'] += '|regex'
      }
      if ([NaN, 0].includes(Number(this.formField.maxLength)) && ![NaN, 0].includes(Number(this.formField.minLength))) {
        payload['validation-formkit'] += `|length:${this.formField.minLength},${this.formField.maxLength || 9999999999}`
      }
      payload.disabled = this.formField.validateSelected && this.formField.validateSelected.includes('readonly')
      payload['wrap-class'] = [payload.classSelected]
      payload.options = this.rows || null
      if (this.checkGroup()) {
        this.$set(payload, 'option-groups', { ...this.mapArrayToObjectGroup(this.rows) })
        payload.group = true
      } else {
        this.$set(payload, 'option-groups', null)
        payload.group = false
      }
      if (this.formField.type === 'treeSelect') {
        payload.options = this.handleTreeSelect(payload.options, this.checkGroup())
      }
      if (payload.apiData) {
        this.$store.dispatch('qtht/getOptionSelect', payload.url).then(res => {
          payload.options = res.data[payload.data].map(r => ({ value: r[payload.configApi.name], label: r[payload.configApi.label], group: r[payload.configApi.group] }))
          if (payload.configApi.group) {
            this.$set(payload, 'option-groups', { ...this.mapArrayToObjectGroup(payload.options) })
            payload.group = true
          } else {
            this.$set(payload, 'option-groups', null)
            payload.group = false
          }
          if (this.formField.type === 'treeSelect') {
            payload.options = this.handleTreeSelect(payload.options, payload.configApi.group)
          }
          if (['radio', 'checkbox'].includes(this.formField.type)) {
            payload.options = { ...this.mapArrayToObject(payload.options) }
          }
          this.$nextTick(() => {
            this.$emit('update-control', payload)
          })
        })
      }
      if (payload.type === 'table') {
        payload.options = this.configTableRows
      }
      this.$emit('update-control', payload)
      this.hide()
    },
    handleTreeSelect(options, isGroup) {
      if (isGroup) {
        return this.mapArrayGroupSelectMultiple(options)
      }
      return options
    },
    deleteRow(index) {
      this.rows.splice(index, 1)
    },
    updatedRow(index, data, target) {
      this[target][index] = data
      this.keyTable += 1
    },
    checkGroup() {
      let isGroup = false
      this.rows.forEach(row => {
        if (row.group) {
          isGroup = true
        }
      })
      return isGroup
    },
    mapArrayToObjectGroup(data) {
      const groupUnique = [...new Set(data.map(i => i.group))]
      const result = {}
      groupUnique.forEach(group => {
        const groups = data.filter(row => row.group === group)
        result[group] = {}
        groups.forEach(group1 => {
          result[group][group1.label] = group1.value
        })
      })
      return result
    },
    mapArrayToObject(data) {
      const result = {}
      data.forEach(group => {
        result[group.label] = group.value
      })
      return result
    },
    addNewOption() {
      this.rows.push({ value: '', label: '', group: '' })
    },
    addNewColumnConfig() {
      this.configTableRows.push({ columnName: '', attribute: '', dataType: '' })
    },
    mapArrayGroupSelectMultiple(data) {
      const groupUnique = [...new Set(data.map(i => i.group))]
      return groupUnique
        ? groupUnique.map(group => ({
          label: group,
          child: data.filter(row => row.group === group),
        }))
        : data
    },
    getControlList() {
      const list = []
      CONTROL_LIST.forEach(group => {
        list.push(...group.children)
      })
      return list.map(control => ({
        text: control.label,
        value: control.id,
        label: control.label,
        type: control.type,
        unit: control.unit,
        configApi: control.configApi || null,
        showElement: control.showElement || [],
      })).filter(control => control.type !== 'html')
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
      }
    },
    setDataType(value) {
      const control = this.controlList.find(ctl => ctl.value === value)
      if (control) {
        this.formField.unit = control.unit
        this.formField.title = control.label
        this.formField.type = control.type
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title-api {
  font-weight: bold;
  margin-right: 10px;
}

.checkbox-api {
  display: inline !important;
}

.detail {
  pointer-events: none
}

.hide-condition {
  color: red;
}
</style>
